export const pg_Files = [
    {   id: 0,
        en: "Documents for downloading",
        ru: "Документы для скачивания",
    },
    {   id: 1,
        en: "Download",
        ru: "Скачать",
    },
    {   id: 2,
        en: "Russian",
        ru: "Русском",
    },
    {   id: 3,
        en: "English",
        ru: "Английском",
    },
    {   id: 4,
        en: "in",
        ru: "на",
    },
]
export const pg_FilesTits = [
    {   id: 0,
        en: "Full CV (resume) in PDF format",
        ru: "Полное резюме в PDF формате",
        file_ru: '/Samborsky_CV_rus.pdf',
        file_en: '/Samborsky_CV_eng.pdf',
        file: '',
        ico: '1',
    },
    {   id: 1,
        en: "Scan of University Diploma",
        ru: "Диплом об окончании университета",
        file_ru: '',
        file_en: '',
        file: '/img/files/Artist_Diploma.pdf',
        ico: '2',
    },
    {   id: 2,
        en: "Scan of Art School Diploma",
        ru: "Диплом об окончании художественной школы",
        file_ru: '',
        file_en: '',
        file: '/img/files/Artist_Diploma.pdf',
        ico: '2',
    },
    {   id: 3,
        en: "Details of a legal entity in Georgia",
        ru: "Реквизиты юридического лица в Грузии",
        file_ru: '',
        file_en: '',
        file: '/img/files/Geo_SR_Details.pdf',
        ico: '3',
    },
    {   id: 4,
        en: "Details of a self-employed account in RF",
        ru: "Реквизиты самозанятого в России",
        file_ru: '',
        file_en: '',
        file: '/img/files/Self_Employed.pdf',
        ico: '3',
    },
]