import React, {useContext, useEffect, useState} from 'react';
import {AuthContext} from '../context/Context.js';
import {publicRoutes} from "../context/AppRoutes"
import {pg_Portfolio2, pg_Portfolio1} from "../context/pg_Portfolio"
import {globPrice_Portfolio} from "../context/GlobPrices"
import {Link} from "react-router-dom";

const PortfolioPg = () => {
    // states and variables
    const {lang, exchange, isLandscape, isLaptop, isPad, appFirstLoad, setAppFirstLoad, toTopListener, setToTopListener} = useContext(AuthContext)
    const [pgLoaded, setPgLoaded] = useState(false)

    // functions
    function groupNumber(data) {
        let tmp = data.toLocaleString('ru-RU')
        return tmp
    }

    // loading
    useEffect(() => {
        if(pgLoaded){
            return
        }else{
            setPgLoaded(true)
        }

        let descStartTimeOffset = 2500
        let mobStartTimeOffset = 1900
        if(!appFirstLoad){
            descStartTimeOffset = 800
            mobStartTimeOffset = 600
        }

        if(isLandscape){
            let po1 = 4
            let po2 = '0.75vw'
            let po3 = '0.75vw'
            if(isLaptop){
                po1 = 3
                po2 = '0.95vw'
                po3 = '0.85vw'
            }

            setTimeout(function () {
                document.querySelectorAll('.page-portfolio-wrp .work').forEach((work, i) => {
                    let y = Math.trunc(i / po1)   // rows
                    let z = i % po1                        // columns
                    setTimeout(function () {
                        work.children[0].children[0].style.width = '100%'
                        work.children[0].children[1].style.backgroundColor = '#2adeff'
                    }, z*100+y*500)
                    setTimeout(function () {
                        work.children[0].children[1].style.width = '100%'
                        work.children[1].style.width = '100%'
                        work.children[1].style.color = '#ccc'
                        work.children[2].style.width = '100%'
                        work.children[2].style.color = '#999'
                        work.children[0].children[1].style.backgroundColor = '#00abb3'
                    }, z*300+y*500+800)
                    setTimeout(function () {
                        work.children[0].children[0].style.opacity = '1'
                    }, z*300+y*500+1800)
                    setTimeout(function () {
                        work.children[0].children[1].style.opacity = '0'
                    }, z*300+y*500+1900)
                    setTimeout(function () {
                        work.children[0].children[1].style.transition = 'all 1s, color 0s, padding 0s, background 0s, border-radius 0s'
                    }, z*300+y*500+2900)
                    setTimeout(function () {
                        work.children[0].children[1].style.color = '#fff'
                        work.children[0].children[1].style.top = 'unset'
                        work.children[0].children[1].style.paddingTop = po2
                        work.children[0].children[1].style.paddingBottom = po3
                        work.children[0].children[1].style.backgroundColor = '#0007'
                        work.children[0].children[1].style.borderRadius = '0'
                        work.classList.add('work-loaded')
                    }, z*300+y*500+3000)
                })
            }, descStartTimeOffset)
        }else{
            let po2 = "100vh"
            let po3 = "6vw"
            let po4 = 1
            let po5 = 150
            if(isPad){
                po2 = "70vh"
                po3 = "4vw"
                po4 = 2
                po5 = 300
            }

            // add listeners to .TOTOP
            if(!toTopListener){
                setToTopListener(true)

                // when footer
                function onEntry(entry) {
                    if(document.querySelector('.totop')){
                        entry.forEach(change => {
                            if (change.isIntersecting) {
                                document.querySelector('.totop').classList.add('totop-end')
                            }else{
                                document.querySelector('.totop').classList.remove('totop-end')
                            }
                        });
                    }
                }
                let observer = new IntersectionObserver(onEntry, { threshold: [0.5] });
                observer.observe(document.querySelector('.footer-com'));

                // when scroll
                document.addEventListener("scroll", function () {
                    if(document.querySelector('.totop')){
                        if(window.scrollY > 200){
                            document.querySelector('.totop').style.opacity = "1"
                        }else{
                            document.querySelector('.totop').style.opacity = "0"
                        }
                    }
                })
            }

            setTimeout(function () {
                document.querySelector('h1').style.opacity = "1"
                document.querySelectorAll('.page-portfolio-wrp .work').forEach((work, i) => {
                    let y = Math.trunc(i / po4)   // rows
                    setTimeout(function () {
                        work.style.width = "100%"
                    }, y*po5)
                    if(!isPad){
                        setTimeout(function () {
                            work.style.marginLeft = "0"
                        }, y*po5+500)
                    }
                    setTimeout(function () {
                        work.style.maxHeight = po2
                        work.style.paddingBottom = po3
                        work.children[0].children[0].style.opacity = "1"
                    }, y*500+2000)
                    setTimeout(function () {
                        work.children[0].style.backgroundColor = "transparent"
                    }, i*500+4000)
                    if(!isPad){
                        setTimeout(function () {
                            work.style.boxShadow = "rgba(0,0,0,.4) 0 2vw 5vw"
                        }, i*500+4500)
                    }
                })
            }, mobStartTimeOffset)
        }

        setAppFirstLoad(false)
    }, [isLandscape, isLaptop, isPad, appFirstLoad, setAppFirstLoad, pgLoaded, setPgLoaded, toTopListener, setToTopListener])

    return (
        <div className='page page-portfolio'>
            {!isLandscape
                ? <h1>{publicRoutes[3][`name_${lang}`]}</h1>
                : ""
            }
            <div className="page-portfolio-wrp">
                {pg_Portfolio2.map((work, i) => (
                    <Link className="work" key={work.id} to={`/case/${work.id}${lang !== 'en' ? '/' + lang : ''}`}>
                        <div>
                            <img src={`/img/portfolio/${work.folder}/${work.pic}`} alt={work[`cat_${lang}`]}/>
                            <p>{work.name_en
                                ? pg_Portfolio1[0][`${lang}`] + ' «' + work[`name_${lang}`] + '»'
                                : pg_Portfolio1[0][`${lang}`] + ' ' + pg_Portfolio1[2][`${lang}`]
                            }</p>
                        </div>
                        <h2>{work[`cat_${lang}`]}</h2>
                        <h3>{work[`work_${lang}`]}</h3>
                        <p className="work-price">{pg_Portfolio1[1][`${lang}`]}{
                                work.name_en
                                    ? ' '
                                    : ' ' + pg_Portfolio1[3][`${lang}`] + ' '
                            } ~ {work.hours} {pg_Portfolio1[10][`${lang}`]}</p>
                    </Link>
                ))}
            </div>
            {!isLandscape
                ? <div>
                    <div className="totop" onClick={() => {window.scrollTo(0, 0)}}>▲</div>
                    <div className="bottom-empty"> </div>
                  </div>
                : ""
            }
        </div>
    );
};

export default PortfolioPg;