import React, {useContext, useEffect, useRef} from 'react';
import {AuthContext} from '../context/Context.js'
import {basicText} from "../context/BasicInterfaceText"
import wa from "../img/whatsapp.jpg";
import tg from "../img/telegram.jpg";

const Footer = () => {
    const {lang, isLandscape} = useContext(AuthContext)

    // refs
    const soctext = useRef()
    const soc1 = useRef()
    const soc2 = useRef()
    const soc4 = useRef()
    const topPhone = useRef()
    const comtext = useRef()
    const topMess1 = useRef()
    const topMess2 = useRef()

    // loading
    useEffect(() => {
        if(isLandscape){
            setTimeout(function (){
                soctext.current.style.opacity = '1'
                setTimeout(function (){
                    soc1.current.style.opacity = '1'
                }, 300)
                setTimeout(function (){
                    soc2.current.style.opacity = '1'
                }, 600)
                setTimeout(function (){
                    soc4.current.style.opacity = '1'
                }, 900)
            }, 5200) // показываем соцсети
            setTimeout(function (){
                comtext.current.style.opacity = '1'
            }, 7000) // показываем юрлицо
        }else{
            setTimeout(function (){
                comtext.current.style.opacity = '1'
                setTimeout(function (){
                    soc1.current.style.opacity = '1'
                    setTimeout(function (){
                        soc2.current.style.opacity = '1'
                    }, 300)
                    setTimeout(function (){
                        soc4.current.style.opacity = '1'
                    }, 600)
                    setTimeout(function (){
                        topPhone.current.style.opacity = '1'
                    }, 1500)
                }, 1000)
            }, 4000)
        }
    })

    return (
        <div className="footer">
            <div className="footer-soc">
                {isLandscape ? <span ref={soctext}>{basicText[10][lang]}</span> : ''}
                <a ref={soc1} href="https://www.instagram.com/samborsky.pro" className="footer-soc-ico footer-soc-ico-inst" target="_blank" rel="noreferrer">
                    <svg version="1.1" viewBox="0 0 16 16">
                        <path d="M11.6,0H4.4C2,0,0,2,0,4.4v7.2C0,14,2,16,4.4,16h7.2c2.4,0,4.4-2,4.4-4.4V4.4
                                C16,2,14,0,11.6,0z M8,11.4c-1.9,0-3.4-1.5-3.4-3.4c0-1.8,1.4-3.3,3.2-3.4c0.1,0,0.1,0,0.2,0c1.8,0,3.3,1.4,3.4,3.3c0,0,0,0.1,0,0.1
                                C11.3,9.8,9.8,11.3,8,11.4z M13,4.5c-0.4,0.4-1.1,0.4-1.5,0c0,0,0,0,0,0c-0.4-0.4-0.4-1,0-1.5c0,0,0,0,0,0C12,2.6,12.6,2.6,13,3
                                C13.4,3.4,13.4,4,13,4.5C13,4.4,13,4.4,13,4.5z"/>
                    </svg>
                </a>
                <a ref={soc2} href="https://vk.com/samborsky_r" className="footer-soc-ico footer-soc-ico-vk" target="_blank" rel="noreferrer">
                    <svg version="1.1" viewBox="0 0 24.4 15.9">
                        <path d="M10,3.7c0,0,0.2,6-0.5,6.4C8.9,9.8,7.2,6.4,5.7,3.5c-0.5-1-0.5-0.9-0.9-2
                                C4.7,1.4,4.6,1.2,4.5,1.1C4,0.8,4,0.7,3.4,0.7l-2.6,0C0.5,0.7,0.3,0.8,0.1,1C0,1.2,0,1.5,0.1,1.7c0,0,2.8,7.3,6.1,11
                                c1.5,1.9,3.8,3.1,6.3,3.2l0,0v0h1.5c0.3,0,0.5-0.1,0.7-0.3c0.1-0.2,0.2-0.5,0.2-0.7c0,0,0-2.2,0.9-2.6s2.1,2.2,3.4,3.1
                                c0.5,0.4,1.1,0.6,1.7,0.6l2.4-0.1c0.6,0.1,1.1-0.4,1.1-0.9c0-0.3,0-0.5-0.2-0.7c-0.7-1.2-1.5-2.3-2.5-3.2c-2.1-2.2-1.8-1.8,0.7-5.6
                                c1.6-2.3,2.2-3.7,2-4.3C24.2,0.6,23,0.7,23,0.7l-2.8,0c-0.2,0-0.3,0-0.5,0.1c-0.2,0.1-0.3,0.3-0.3,0.5C19,2.4,18.5,3.5,18,4.6
                                c-1.7,3.2-3.7,3.1-3.7,3.1s-0.2-1.1-0.2-1.5c-0.1-1.5,0.2-5.8-0.1-6C13.4,0,12.8-0.1,12.2,0c-1.4,0-1.2,0-1.9,0.4
                                C9.9,0.6,9.7,0.9,9.7,1.1C10,1.7,10,3.7,10,3.7z"/>
                    </svg>
                </a>
                <a ref={soc4} href="https://www.linkedin.com/in/samborsky-r" className="footer-soc-ico footer-soc-ico-in" target="_blank" rel="noreferrer">
                    <svg version="1.1" viewBox="0 0 66.5 67">
                        <path className="st0" d="M15.5,20.7H0.9v42.9h14.7L15.5,20.7L15.5,20.7z M2.2,14.7c1.6,1.4,3.7,2.1,5.9,2c2.1,0.1,4.2-0.6,5.9-2
                                c1.5-1.3,2.3-4,2.3-6.1S15.5,3.8,14,2.4c-1.6-1.4-3.7-2.1-5.9-2c-2.1,0-4.2,0.7-5.9,2C0.7,3.8,0,6.5,0,8.6S0.7,13.3,2.2,14.7z
                                 M24.2,20.7v42.9h14.6V35.4c1.3-2.2,3.8-3.5,6.4-3.4c2.5,0,4.2,0.6,5.2,1.7c1,1.1,1.4,2.9,1.4,5.2v24.6h14.7V38.3
                                c-0.1-5.9-1.3-11.3-3.8-14.2s-6.1-4.4-10.9-4.4c-5.7,0-10.2,2.1-13.4,6.4l-0.5-5.5L24.2,20.7L24.2,20.7z"/>
                    </svg>
                </a>
            </div>
            {!isLandscape ? <a href={"tel:+" + basicText[8]['data']} className="footer-phone"  ref={topPhone}>{"+" + basicText[8]['data']}</a> : ''}
            <div ref={comtext} className="footer-com">
                {!isLandscape ? basicText[0][lang]+' ' : ''}
                © {basicText[52][lang]}
                {!isLandscape ?
                    <div>
                        <a href={"https://wa.me/+" + basicText[6]['data']} target="_blank" rel="noreferrer" className="footer-mess"  ref={topMess1}>
                            <img src={wa} alt=""/>
                        </a>
                        <a href={"https://t.me/" + basicText[7]['data']} target="_blank" rel="noreferrer" className="footer-mess"  ref={topMess2}>
                            <img src={tg} alt=""/>
                        </a>
                    </div>
                : ''}
            </div>
        </div>
    );
};

export default Footer;