export const pg_Team1 = [
    {   id: 0,
        en: "Career",
        ru: "История",
    },
    {   id: 1,
        en: "history in companies",
        ru: "карьеры в компаниях",
    },
    {   id: 2,
        en: "My first passion for digital graphics began at the age of 6 - I was drawing frame-by-frame animations in CPEN on an IBM386. In high school, I was into creating 3D maps for shooters like Unreal. And when I entered university to become a programmer and the Internet burst into my consciousness, I directed my creative energy to creating animated sites using ActionScript and Macromedia Flash.",
        ru: "Первые увлечения цифровой графикой начались в 6 лет - тогда я рисовал покадровые анимации в CPEN на IBM386. В старших классах увлёкался созданием 3D карт для шутеров вроде Unreal. А когда поступил в университет на программиста и в моё сознание ворвался интернет, то направил свою творческую энергию на создание анимационных сайтов средствами ActionScript и Macromedia Flash.",
    },


    {   id: 3,
        en: "Samborsky Roman",
        ru: "Самборский Роман",
    },
    {   id: 4,
        en: "FULL-STACK WEB DEVELOPER",
        ru: "ФУЛСТЭК ВЕБ РАЗРАБОТЧИК",
    },
    {   id: 5,
        en: "Collapse details",
        ru: "Свернуть подробности",
    },
    {   id: 6,
        en: "click for",
        ru: "жми, узнай",
    },
    {   id: 7,
        en: "details",
        ru: "детали",
    },


    {   id: 8,
        en: "Education",
        ru: "Образование",
    },
    {   id: 9,
        en: "Details",
        ru: "Детали",
    },
    {   id: 10,
        en: "Experience in years",
        ru: "Опыт в годах",
    },


    {   id: 11,
        en: "Novosibirsk State University of Civil Engineering and Architecture",
        ru: "Новосибирский Государственный Архитектурно-Строительный Университет",
    },
    {   id: 12,
        en: "Information systems and technology department",
        ru: "Фак-т: «Информационные Системы и Технологии»",
    },
    {   id: 13,
        en: "Art school «North Spring»",
        ru: "Художественная школа «Весна»",
    },
    {   id: 14,
        en: "Diploma",
        ru: "Диплом",
    },
    {   id: 15,
        en: "Current address: Cyprus, Paralimni",
        ru: "Текущий адрес: Кипр, Паралимни",
    },
    {   id: 16,
        en: "English level: Pre-Intermediate (A2)",
        ru: "Уровень английского: Pre-Intermediate (A2)",
    },
    {   id: 17,
        en: "Date of Birth: June 23, 1987",
        ru: "Дата рождения: 23.06.87",
    },


    {   id: 18,
        en: "Team lead (CRM, GIT)",
        ru: "Тимлид (CRM, GIT)",
    },
    {   id: 19,
        en: "Backend (PHP, NodeJS)",
        ru: "Бэкенд (PHP, NodeJS)",
    },
    {   id: 20,
        en: "Frontend (React, JQ)",
        ru: "Фронтенд (React, JQ)",
    },
    {   id: 21,
        en: "Layout (HTML, LESS)",
        ru: "Верстка (HTML, LESS)",
    },
    {   id: 22,
        en: "Design (UX, UI)",
        ru: "Дизайн (UX, UI)",
    },
    {   id: 23,
        en: "Branding",
        ru: "Брендинг",
    },
    {   id: 24,
        en: "Polygraphy",
        ru: "Полиграфия",
    },
    {   id: 25,
        en: "Raster illustrations",
        ru: "Иллюстрации (растра)",
    },
    {   id: 26,
        en: "Vector illustrations",
        ru: "Иллюстрации (вектор)",
    },
    {   id: 27,
        en: "3D illustrations",
        ru: "Иллюстрации (3D)",
    },

    {   id: 28,
        en: ", and also marked on the map the experience gained from living in different parts of the world",
        ru: ", а также отметил на карте полученный опыт жизни в разных точках мира",
    },
    {   id: 29,
        en: "After graduating from the university, I got a job at a web studio as a designer and in the following years I mastered layout, then frontend, and then backend. After 14 years of work, I immersed myself in many other aspects of the web industry. I have described the skills acquired during my career in detail by year below",
        ru: "После окончания ВУЗа устроился в web-студию дизайнером и в последующие годы освоил вёрстку, затем фронтенд, а после и бэкенд. За прошедшие 14 лет работы погружался и во многие другие грани web-индустрии. Навыки приобретённые во время карьеры подробно расписал по годам ниже",
    },

    {   id: 30,
        en: "14 years",
        ru: "14 лет",
    },
    {   id: 31,
        en: "experience in web development",
        ru: "опыт в web разработке",
    },
    {   id: 32,
        en: "until",
        ru: "по",
    },
]

export const pg_Team2 = [
    {   id: 0,
        pic_top: "4",
        pic_y: "",
        end_en: "current time",
        end_ru: "текущее время",
        time_en: "Over 4 years",
        time_ru: "Более 4 лет",

        company_en: "GC Kenyx Franchise (RF)",
        company_ru: "ГК Kenyx Франчази (Россия)",
        prof_en: "Full-stack developer (Frontend, Backend, UX, UI)",
        prof_ru: "Фулстек разработка (Frontend, Backend, UX, UI)",
        text_en: "Development, refinement and support of client and internal projects of a fence and gate manufacturing plant. While working on this project, I became a confident ReactJS framework programmer and implemented global API integration of projects with a common AMO CRM for synchronization of orders, clients and payments. I also mastered the work of Docker containers.",
        text_ru: "Разработка, доработка и поддержка клиентских и внутренних проектов завода по производству ограждений и ворот. Занимаясь этим проектом я стал уверенным программистом фреймворка ReactJS и внедрил глобальную API интеграцию проектов с общей AMO CRM для синхронизации заказов, клиентов и оплат. Также освоил работу Docker контейнеров.",
    },
    {   id: 1,
        pic_top: "3",
        pic_y: "",
        end_en: "current time",
        end_ru: "текущее время",
        time_en: "Over 3 years",
        time_ru: "Более 3 лет",

        company_en: "WelcomeGeo Invest (Turkey, Georgia, Cyprus)",
        company_ru: "WelcomeGeo Invest (Турция, Грузия, Кипр)",
        prof_en: "Full-stack developer (Frontend, Backend, UX, UI, SEO, Team lead)",
        prof_ru: "Фулстек разработка (Frontend, Backend, UX, UI, SEO, Team lead)",
        text_en: "Development, refinement and support of several projects in different countries of a real estate company. A separate team was formed to manage projects in each country. While working on this project, I gained strong knowledge in SEO, as well as in working with the API of Google, Yandex services and the mechanics of automatic uploading of catalogs to stocks and aggregators.",
        text_ru: "Разработка, доработка и поддержка нескольких проектов в разных странах компании занимающейся недвижимостью. Для ведения проектов в каждой из стран сформирована своя команда. Занимаясь этим проектом я обрёл сильные знания в SEO, а также в работе с API сервисов Google, Yandex и механик автовыгрузки каталогов на стоки и агрегаторы.",
    },
    {   id: 2,
        pic_top: "2",
        pic_y: 1,
        end_en: "September 2021",
        end_ru: "Сентябрь 2021",
        time_en: "2 years 3 months",
        time_ru: "2 года 3 месяца",

        company_en: "Capperroom (international activity) startup",
        company_ru: "Capperroom (международная деятельность) стартап",
        prof_en: "Frontend & partially Backend",
        prof_ru: "Frontend и частично Backend",
        text_en: "Design and development of a unique aggregator for sports analysts. While working on this project, I switched to working with GIT, mastered Symfony, deployed Linux (Ubuntu) + Nginx server from scratch.",
        text_ru: "Проектирование и разработка уникального по механике агрегатора для спортивных аналитиков. Занимаясь этим проектом перешёл на работу с GIT, освоил Symfony, развернул Linux (Ubuntu) + Nginx сервер с нуля.",
    },
    {   id: 3,
        pic_top: "1",
        pic_y: "",
        end_en: "June 2019",
        end_ru: "Июнь 2019",
        time_en: "1 year 7 months",
        time_ru: "1 год 7 месяцев",

        company_en: "GC Your Style (RF)",
        company_ru: "ГК Твой Стиль (Россия)",
        prof_en: "Frontend + adapt to CMS (Wordpress и Bitrix), UX, UI",
        prof_ru: "Frontend + натяжка CMS (Wordpress и Bitrix), UX, UI",
        text_en: "Creation, reconstruction and support of current applications and company websites. Management of advertising projects. During this period I mastered: Less, JQ, Bootstrap, Sublime T3 + Koala, Google and Yandex analytics.",
        text_ru: "Создание, реконструкция и поддержка текущих приложений и сайтов компании. Ведение рекламных проектов. В этот период мною были освоены: Less, JQ, Bootstrap, Sublime T3 + Koala, аналитика Google и Яндекс.",
    },
    {   id: 4,
        pic_top: "1",
        pic_y: "",
        end_en: "October 2017",
        end_ru: "Октябрь 2017",
        time_en: "2 years 8 months",
        time_ru: "2 года 8 месяцев",

        company_en: "Laximo LTD (RF)",
        company_ru: "Laximo LTD (Россия)",
        prof_en: "UX UI designer, Team lead",
        prof_ru: "UX UI дизайнер, Team lead",
        text_en: "Working in a large team on a large project (websites, applications, marketing). Related work as a team leader. Usability and visitor behavior analytics. Development of user tools. Creation of interactive prototypes. During this period, I mastered Sketch, Moqups, Avacode.",
        text_ru: "Работа в большой команде над крупным проектом (сайты, приложения, маркетинг). Сопутствующая работа как тимлида. Аналитика юзабилити и поведения посетителей. Проработка пользовательского инструментария. Создание интерактивных прототипов. В этот период освоил Sketch, Moqups, Avacode.",
    },
    {   id: 5,
        pic_top: "1",
        pic_y: "",
        end_en: "May 2015",
        end_ru: "Май 2015",
        time_en: "2 years",
        time_ru: "2 года",

        company_en: "Freelance (international activity)",
        company_ru: "Фриланс (международная деятельность)",
        prof_en: "UX UI designer, 2D 3D illustrator, Project manager",
        prof_ru: "UX UI дизайнер, 2D 3D иллюстратор, Менеджер проектов",
        text_en: "Fulfilled orders from web studios and private companies for UX, UI, branding, printing, 2D 3D illustrations, animations, hosting, management, advertising setup. Gained experience in managing and selecting specialists in related professions for developing turnkey orders (web or app). During this period, mastered: Axure, After Effects, Zeplin and much more.",
        text_ru: "Выполнял заказы от веб студий и частных компаний по UX, UI, брендингу, полиграфии, 2D 3D иллюстрациям, анимациям, ведению, хостингу, настройке рекламы. Приобрёл опыт управления и подбора специалистов смежных профессий для разработки заказов под ключ (web или app). В этот период освоил: Axure, After Effects, Zeplin и многое другое.",
    },
    {   id: 6,
        pic_top: "1",
        pic_y: "",
        end_en: "May 2013",
        end_ru: "Май 2013",
        time_en: "1 year",
        time_ru: "1 год",

        company_en: "IT Construct (RF: Novosibirsk)",
        company_ru: "IT Construct (РФ: Новосибирск)",
        prof_en: "UI designer, 2D illustrator",
        prof_ru: "UI дизайнер, 2D иллюстратор",
        text_en: "Was responsible for graphic solutions. Gained experience in teamwork in a studio with network architecture. Began to apply guidelines and a project management system. During this period, mastered Adobe Illustrator and some team software.",
        text_ru: "Был ответственным за графические решения. Приобрёл опыт командной работы в студии с сетевой архитектурой. Стал применять гайдлайны и систему управления проектами. В этот период освоил Adobe Illustrator и некоторый командный софт.",
    },
    {   id: 7,
        pic_top: "1",
        pic_y: "",
        end_en: "May 2012",
        end_ru: "Май 2012",
        time_en: "1 year 6 months",
        time_ru: "1 год 6 месяцев",

        company_en: "Global Technology (RF: Novosibirsk)",
        company_ru: "Global Technology (РФ: Новосибирск)",
        prof_en: "UI designer, 2D 3D illustrator & animator",
        prof_ru: "UI дизайнер, 2D 3D иллюстратор и аниматор",
        text_en: "Was engaged in web design, advertising products, as well as visualizations and animations. During this period, he actively mastered Flash, FireWorks, Photoshop and 3Ds Max.",
        text_ru: "Занимался web дизайном, рекламной продукцией, а также визуализациями и анимациями. В этот период активно осваивал Flash, FireWorks, Photoshop и 3Ds Max.",
    },
    {   id: 8,
        pic_top: "1",
        pic_y: "",
        end_en: "December 2010",
        end_ru: "Декабрь 2010",
        time_en: "1 year 6 months",
        time_ru: "1 год 6 месяцев",

        company_en: "Novosoft (RF: Novosibirsk)",
        company_ru: "Novosoft (РФ: Новосибирск)",
        prof_en: "UI designer, 2D illustrator",
        prof_ru: "UI дизайнер, 2D иллюстратор",
        text_en: "Was engaged in interface design for the company's software. Worked with advertising, printing and the company's brand. During this period, mastered Corel Draw.",
        text_ru: "Занимался оформлением интерфейсов для софта компании. Работал с рекламной, полиграфией и брендом компании. В этот период освоил Corel Draw.",
    },
]

export const pg_Team3 = [
    {   id: 0,
        city_en: "Brazil",
        city_ru: "Бразилия",
        left: '21',
        top: '30.5',
        who: '2019',
        main: false,
        mirror: false,
    },
    {   id: 1,
        city_en: "Valencia",
        city_ru: "Валенсия",
        left: '36',
        top: '18.2',
        who: '2018',
        main: false,
        mirror: false,
    },
    {   id: 2,
        city_en: "Montenegro",
        city_ru: "Черногория",
        left: '40.5',
        top: '16',
        who: '2017',
        main: false,
        mirror: true,
    },
    {   id: 3,
        city_en: "Riga",
        city_ru: "Рига",
        left: '42.5',
        top: '11.5',
        who: '2021',
        main: false,
        mirror: true,
    },
    {   id: 4,
        city_en: "Hurghada",
        city_ru: "Хургада",
        left: '44.4',
        top: '21.5',
        who: '2020',
        main: false,
        mirror: false,
    },
    {   id: 5,
        city_en: "St. Petersburg",
        city_ru: "Санкт-Петербург",
        left: '44',
        top: '10.3',
        who: '2021',
        main: false,
        mirror: false,
    },
    {   id: 6,
        city_en: "Cyprus & Alanya",
        city_ru: "Кипр и Аланья",
        left: '44.2',
        top: '18.8',
        who: '2020-2024',
        main: true,
        mirror: false,
    },
    {   id: 7,
        city_en: "Batumi",
        city_ru: "Батуми",
        left: '46.8',
        top: '17.2',
        who: '2023',
        main: false,
        mirror: false,
    },
    {   id: 8,
        city_en: "Novosibirsk",
        city_ru: "Новосибирск",
        left: '57.6',
        top: '12.6',
        who: '1987-2016',
        main: false,
        mirror: false,
    },
    {   id: 9,
        city_en: "Danang",
        city_ru: "Дананг",
        left: '64',
        top: '25.5',
        who: '2024',
        main: false,
        mirror: false,
    },
]






























