export const pg_AnswersText0 = [
    {   id: 0,
        en: "This will be interesting for you too",
        ru: "Это вам будет тоже интересно",
    },
    {   id: 1,
        en: "In this section I have collected answers to questions that I think you will probably be interested in. If the answer to your question is not fully disclosed or is not in this list at all, write it by clicking on the button «I want to ask about something else».",
        ru: "В данном разделе собрал ответы на вопросы, которые, как мне кажется, наверняка вам будут интересны. Если ответ на ваш вопрос не раскрыт полностью или вовсе отсутствует в этом списке - напишите его, нажав на кнопку «Хочу спросить о другом».",
    },
    {   id: 2,
        en: "I want to ask about something else",
        ru: "Хочу спросить о чём-то другом",
    },
]

export const pg_AnswersText1 = [
    {   id: 0,
        en: "What tasks do you prefer to do?",
        ru: "Какими задачами предпочитаете заниматься?",
    },
    {   id: 1,
        en: "Which of your projects are you proud of?",
        ru: "Каким из ваших проектов вы гордитесь?",
    },
    {   id: 2,
        en: "Are you ready to move to another country?",
        ru: "Готовы ли к переезду в другую страну?",
    },
    {   id: 3,
        en: "Do you want to work in an office?",
        ru: "Хотите работать в офисе?",
    },
    {   id: 4,
        en: "Why are you looking for a new job?",
        ru: "Почему ищите новую работу?",
    },
    {   id: 5,
        en: "How do you resolve conflicts in a team?",
        ru: "Как разрешаете конфликты в команде?",
    },
    {   id: 6,
        en: "What do you do if you can't solve a problem?",
        ru: "Что делаете, если не можете решить задачу?",
    },
    {   id: 7,
        en: "Can you complete a test task?",
        ru: "Сможете выполнить тестовое задание?",
    },
    {   id: 8,
        en: "What do you do in your free time?",
        ru: "Чем занимаетесь в свободное от работы время?",
    },
]

export const pg_AnswersText2 = [
    {   id: 0,
        en: "At the design stage, I like to do design (UX). In the frontend, I get bored at the layout stage - although it is simple, it is so tedious. In the backend, I am interested in everything related to programming, but I don’t really like setting up servers and digging into their software.",
        ru: "На этапе дизайна мне нравится заниматься проектированием (UX). Во фронтенде скучаю на этапе вёрстки - хоть она и простая, но такая муторная. В бэкенде же мне интересно все связанное с программированием, а вот настраивать сервера и капаться в их софте - ни очень люблю.",
    },
    {   id: 1,
        en: "Definitely the most interesting and complex project for me was KENYX-CRM. At all stages of the project development, I had to solve unique problems, analogs to which I could not find. Also, in the process of working with it, I discovered all the charm of the ReactJS framework. There is a second project WelcomeGeo - in which I was also lucky to create many cool and complex mechanics, although they were implemented with old tools by modern standards.",
        ru: "Однозначно самым интересным и сложным проектом для меня стал KENYX-CRM. На всех этапах разработки проекта приходилось решать уникальные задачи, аналогов которым я не мог найти. Также в процессе работы с ним открыл для себя всю прелесть фреймворка ReactJS. Есть и второй проект WelcomeGeo - в котором мне тоже посчастливилось создать много крутых и сложных механик, хоть они и были реализованы старыми по современным меркам инструментами.",
    },
    {   id: 2,
        en: "Of course it depends on which country you invite. I would definitely say «Yes» to the Mediterranean countries and Europe in general, as well as the countries of North America and Oceania.",
        ru: "Конечно зависит от того в какую страну пригласите. Однозначно отвечу «Да» странам Средиземноморья и Европы в целом, а также странам Северной Америки и Океании.",
    },
    {   id: 3,
        en: "I work in the office without any problems. It's even more convenient and efficient.",
        ru: "Без каких либо проблем работаю в офисе. Так даже удобнее и эффективнее.",
    },
    {   id: 4,
        en: "I am currently working on two projects and therefore want to get away from part-time work, as it is terribly inconvenient. Also, one of these projects is nearing its logical conclusion, after which my developer services will not be needed. The second project works with me on a piecework basis and there are gaps in the flow of tasks, which I also really don’t like.",
        ru: "Работаю сейчас в двух проектах и поэтому хочу уйти от парт-тайм работы, так как это жутко неудобно. Также один из этих проектов близится к логическому завершению, после чего мои услуги разработчика будут не нужны. Второй же проект работает со мной по сдельной схеме и бывают пробелы в потоке задач, что мне также очень не нравится.",
    },
    {   id: 5,
        en: "To be honest, I can't remember a single conflict at work. In general, I adhere to the principle «The manager knows best how I should act in a controversial situation.»",
        ru: "Если честно, то я не могу и вспомнить ни одного конфликта на работе. В общем придерживаюсь принципа «Руководителю виднее, как мне нужно поступать в спорной ситуации».",
    },
    {   id: 6,
        en: "Problems at the implementation stage (technical) - usually have a bunch of solutions on the Internet (or in AI). If I can't find answers online, I turn to the team or to chats of developers I know or to specialized forums. When there is no solution (which happens very rarely), I have to create something completely new and complex where it was not planned. But problems at the design stage (logical) - I always solve with time and searching for analogs. So the record was a task that took 1 week.",
        ru: "Проблемы на стадии реализации (технические) - обычно уже имеют кучу решений в интернете (или у ИИ). Если же ответов в сети не найти, то я обращаюсь к команде или в чаты знакомых разработчиков или профильные форумы. Когда решения нет (что бывает крайне редко), то приходится создавать что-то абсолютно новое и сложное там, где это не планировалось. А вот проблемы на стадии проектирования (логические) - у меня всегда решаются временем и поиском аналогов. Так рекордом стала задача для которой потребовалась 1 неделя.",
    },
    {   id: 7,
        en: "To be honest, I don't like doing this. I think a portfolio is created to assess the level of a specialist. Making ToDo lists and the like is still necessary to check juniors. But if your vacancy is very tempting, and the task is really interesting and, most importantly, not commercial - then so be it, I will do a test task :)",
        ru: "Если честно, то не люблю этим заниматься. Помойму портфолио и создаётся для оценки уровня специалиста. Делать ToDo листы и тому подобное - это всё-таки нужно для проверки джуниоров. Но если ваша вакансия очень заманчивая, а задание действительно интересное и главное не коммерческое - то так и быть, сделаю тестовое задание :)",
    },
    {   id: 8,
        en: "On weekends I like active recreation. Ideally it is: snowboarding, paintball, hiking, water polo... But on weekdays after work I am not so active and usually have no more than enough for friends or a walk around the city or to a cafe :)",
        ru: "В выходные люблю активный отдых. В идеале это: сноуборд, пейнтбол, хайкинг, водное поло... А вот в будние после работы я не так активен и обычно хватает не больше чем на друзей или прогулку по городу или в кафе :)",
    },
]


































