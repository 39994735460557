import React, {useContext, useEffect, useRef, useState} from 'react';
import {AuthContext} from '../context/Context.js';
import {pg_AnswersText0, pg_AnswersText1, pg_AnswersText2} from "../context/pg_Answers";

const AnswersPg = () => {
    // states and variables
    const {lang, isLandscape, isLaptop, isPad, appFirstLoad, setAppFirstLoad, setPops} = useContext(AuthContext)
    const [divide] = useState(() => {
        if(isLandscape){
            return 2
        }else{
            return 1
        }
    })
    const arrLength = pg_AnswersText1.length
    const [pgLoaded, setPgLoaded] = useState(false)
    const [answersAc, setAnswersAc] = useState(() => {
            let tempAnsArr = []
            for(let i = 0; i < arrLength; i++){
                tempAnsArr.push(false)
            }
            return tempAnsArr
        })

    // refs
    const h1 = useRef()
    const h2 = useRef()
    const h3 = useRef()
    
    // functions
    function setActive(num) {
        let tempAnsArr = []

        if(answersAc[num]){
            for(let i = 0; i < arrLength; i++){
                tempAnsArr.push(false)
            }
            setAnswersAc(tempAnsArr)
        }else{
            for(let i = 0; i < arrLength; i++){
                tempAnsArr.push(false)
            }
            tempAnsArr[num] = true
            setAnswersAc(tempAnsArr)
        }
    }

    // loading
    useEffect(() => {
        if(pgLoaded){
            return
        }else{
            setPgLoaded(true)
        }

        let descStartTimeOffset = 2500
        let mobStartTimeOffset = 1900
        if(!appFirstLoad){
            descStartTimeOffset = 600
            mobStartTimeOffset = 600
        }

        if(isLandscape){
            let pr1 = '1vw'
            let pr2 = '6vw'
            if(isLaptop){
                pr1 = '1.2vw'
                pr2 = '7vw'
            }

            setTimeout(function () {
                if(h1){
                    if(h1.current){
                        h1.current.style.opacity = '1'
                    }
                }
                setTimeout(function () {
                    if(h2){
                        if(h2.current){
                            h2.current.style.opacity = '1'
                        }
                    }
                },300)
                setTimeout(function () {
                    document.querySelectorAll('.page-answers-wrp-left .question').forEach((el, i) => {
                        setTimeout(function () {
                            el.style.width = '100%'
                            el.style.marginLeft = '0'
                        },200*i)
                        setTimeout(function () {
                            el.children[0].style.maxHeight = pr2
                            el.children[0].style.paddingTop = pr1
                            el.children[0].style.paddingBottom = pr1
                            el.style.backgroundColor = '#333'
                        },500*i+1500)
                        setTimeout(function () {
                            el.style.boxShadow = "0 1vw 2vw 0 rgba(0, 0, 0, 0.3)"
                        },500*i+3000)
                    })
                    document.querySelectorAll('.page-answers-wrp-right .question').forEach((el, i) => {
                        setTimeout(function () {
                            el.style.width = '100%'
                            el.style.marginLeft = '0'
                        },200*i+100)
                        setTimeout(function () {
                            el.children[0].style.maxHeight = pr2
                            el.children[0].style.paddingTop = pr1
                            el.children[0].style.paddingBottom = pr1
                            el.style.backgroundColor = '#333'
                        },500*i+1750)
                        setTimeout(function () {
                            el.style.boxShadow = "0 1vw 2vw 0 rgba(0, 0, 0, 0.3)"
                        },500*i+3300)
                    })
                },600)
                setTimeout(function () {
                    if(h3){
                        if(h3.current){
                            h3.current.style.opacity = '1'
                        }
                    }
                },7000)
            },descStartTimeOffset)
        }else{
            let pr1 = '4vw'
            let pr2 = '27vw'
            if(isPad){
                pr1 = '3vw'
                pr2 = '20vw'
            }

            setTimeout(function () {
                if(h1){
                    if(h1.current){
                        h1.current.style.opacity = '1'
                    }
                }
                setTimeout(function () {
                    if(h2){
                        if(h2.current){
                            h2.current.style.opacity = '1'
                        }
                    }
                },300)
                setTimeout(function () {
                    document.querySelectorAll('.page-answers-wrp-left .question').forEach((el, i) => {
                        setTimeout(function () {
                            el.style.width = '100%'
                            el.style.marginLeft = '0'
                        },200*i)
                        setTimeout(function () {
                            el.children[0].style.maxHeight = pr2
                            el.children[0].style.paddingTop = pr1
                            el.children[0].style.paddingBottom = pr1
                            el.style.backgroundColor = '#333'
                        },500*i+2000)
                        setTimeout(function () {
                            el.style.boxShadow = "0 2vw 5vw 0 rgba(0, 0, 0, 0.4)"
                        },600*i+4000)
                    })
                },600)
                setTimeout(function () {
                    if(h3){
                        if(h3.current){
                            h3.current.style.opacity = '1'
                        }
                    }
                },9000)
            },mobStartTimeOffset)
        }

        setAppFirstLoad(false)
    }, [isLandscape, isLaptop, isPad, appFirstLoad, arrLength, setAppFirstLoad, pgLoaded, setPgLoaded])

    return (
        <div className='page page-answers'>
            <h1 ref={h1}>{pg_AnswersText0[0][lang]}</h1>
            <h2 ref={h2}>{pg_AnswersText0[1][lang]}</h2>
            <div className="page-answers-wrp">
                <div className="page-answers-wrp-left">
                    {pg_AnswersText1.map((question, i) => {
                        if(i % divide === 0){
                            return(
                                <div className={`question ${answersAc[i] ? "question-active" : ""}`}
                                    key={question.id}>
                                    <div className={`question-tit`} onClick={() => setActive(i)}>
                                        {question[`${lang}`]}
                                    </div>
                                    <div className={`question-answer`}>
                                        {pg_AnswersText2[i][`${lang}`]}
                                    </div>
                                </div>
                            )
                        }
                        return ('')
                    })}
                    <h3 ref={h3} onClick={() => {
                        setPops({
                            p0: true, // popup fon
                            p1: false, // popup - free estimate
                            p2: false, // popup - make money with us
                            p3: false, // popup - discount
                            p4: false, // popup - payment
                            p5: true, // popup - message
                            p6: false, // free variable, you can use it for new popup
                            p7: false, // free variable, you can use it for new popup
                            p8: false, // free variable, you can use it for new popup
                            p9: false, // free variable, you can use it for new popup
                        })
                    }}>{pg_AnswersText0[2][lang]}</h3>
                </div>
                {isLandscape ?
                    <div className="page-answers-wrp-right">
                        {pg_AnswersText1.map((question, i) => {
                            if(i % 2 !== 0){
                                return(
                                    <div className={`question ${answersAc[i] ? "question-active" : ""}`}
                                        key={question.id}>
                                        <div className={`question-tit`} onClick={() => setActive(i)}>
                                            {question[`${lang}`]}
                                        </div>
                                        <div className={`question-answer`}>
                                            {pg_AnswersText2[i][`${lang}`]}
                                        </div>
                                    </div>
                                )
                            }
                            return ('')
                        })}
                    </div>
                : ''}
            </div>
        </div>
    );
};

export default AnswersPg;