import React, {useContext, useEffect, useState} from 'react';
import {AuthContext} from '../context/Context.js';
import {pg_Wishes, pg_WishesTits, pg_WishesTexts} from "../context/pg_Wishes";

const WishesPg = () => {
    // states and vars
    const {lang, isLandscape, appFirstLoad, setAppFirstLoad} = useContext(AuthContext)
    const [pgLoaded, setPgLoaded] = useState(false)

    // loading
    useEffect(() => {
        if(pgLoaded){
            return
        }else{
            setPgLoaded(true)
        }

        let descStartTimeOffset = 2500
        let mobStartTimeOffset = 1900
        if(!appFirstLoad){
            descStartTimeOffset = 600
            mobStartTimeOffset = 600
        }

        if(isLandscape){
            setTimeout(function () {
                if(document.querySelector('h1')){
                    document.querySelector('h1').style.opacity = '1'
                }
                setTimeout(function () {
                    if(document.querySelector('.oneWish')){
                        document.querySelectorAll('.oneWish').forEach((onew, w)=>{
                            setTimeout(function (){
                                if(onew.querySelector('h2')){
                                    onew.querySelector('h2').style.maxWidth = '100%'
                                }
                                setTimeout(function (){
                                    if(onew.querySelector('h2 span')){
                                        onew.querySelector('h2 span').style.backgroundColor = 'transparent'
                                        onew.querySelector('h2 span img').style.opacity = '1'
                                        onew.querySelector('h2').style.color = '#fff'
                                    }
                                },500)
                                setTimeout(function (){
                                    if(onew.querySelector('p')){
                                        onew.querySelector('p').style.maxHeight = '7vw'
                                        onew.querySelector('p').style.color = '#aaa'
                                    }
                                },1500)
                            },300 * w)
                        })
                    }
                },300)
            },descStartTimeOffset)
        }else{
            setTimeout(function () {
                if(document.querySelector('h1')){
                    document.querySelector('h1').style.opacity = '1'
                }
                setTimeout(function () {
                    if(document.querySelector('.oneWish')){
                        document.querySelectorAll('.oneWish').forEach((onew, w)=>{
                            setTimeout(function (){
                                if(onew.querySelector('h2')){
                                    onew.querySelector('h2').style.maxWidth = '100%'
                                }
                                setTimeout(function (){
                                    if(onew.querySelector('h2 span')){
                                        onew.querySelector('h2 span').style.backgroundColor = 'transparent'
                                        onew.querySelector('h2 span img').style.opacity = '1'
                                        onew.querySelector('h2').style.color = '#fff'
                                    }
                                },500)
                                setTimeout(function (){
                                    if(onew.querySelector('p')){
                                        onew.querySelector('p').style.maxHeight = '65vw'
                                        onew.querySelector('p').style.color = '#999'
                                    }
                                },1500)
                            },300 * w)
                        })
                    }
                },300)
            },mobStartTimeOffset)
        }

        setAppFirstLoad(false)
    }, [isLandscape, appFirstLoad, setAppFirstLoad, pgLoaded, setPgLoaded])

    return (
        <div className="page page-wishes">
            <h1>{pg_Wishes[0][lang]}</h1>
            <div className="page-wishes-wrp">
                {pg_WishesTits.map((tit, i) => (
                    <div className="oneWish" key={i}>
                        <h2>
                            <span>
                                <img src={`/img/wishes/${i+1}.png`} alt=""/>
                            </span>
                            {tit[lang]}
                        </h2>
                        <p>{pg_WishesTexts[i][lang]}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default WishesPg;