export const pg_Wishes = [
    {   id: 0,
        en: "Wishes for future work",
        ru: "Пожелания к будущей работе",
    },
]
export const pg_WishesTits = [
    {   id: 0,
        en: "Source of income of the company",
        ru: "Источник заработка компании",
    },
    {   id: 1,
        en: "Work format",
        ru: "Формат работы",
    },
    {   id: 2,
        en: "Revision of salary",
        ru: "Пересмотр ЗП",
    },
    {   id: 3,
        en: "Technologies",
        ru: "Технологии",
    },
]
export const pg_WishesTexts = [
    {   id: 0,
        en: "I give preference to companies whose income is based on stable mechanics. For example, subscription fees for service or own production, or services and goods that have a stable demand in society. Companies with seasonal earnings or selling spinners - cause me concern.",
        ru: "Предпочтение отдаю компаниям чьи доходы базируются на стабильных механиках. Например абонентские платы за сервис или собственное производство, или услуги и товары имеющие стабильный спрос в обществе. Компании c сезонным заработком или продающие спиннеры - вызывают у меня опасения.",
    },
    {   id: 1,
        en: "Part-time or piecework work is of much less interest than working in a team on a large and ongoing project. It is important that work plans are scheduled at least a year in advance.",
        ru: "Работа в формате парт-тайм или сдельная - интересует гораздо меньше, чем работа в команде над большим и не останавливающимся в развитии проекте. Важно чтобы планы по работе были расписаны хотя бы на год вперёд.",
    },
    {   id: 2,
        en: "At least once a year, wages should be adjusted, at least within the limits of inflation.",
        ru: "Не реже чем раз в год должна происходить коррекция заработной платы, как минимум в рамках инфляции.",
    },
    {   id: 3,
        en: "If in project using advanced development technologies, this will also be a big plus.",
        ru: "Если в работе используются передовые технологии разработки - это также будет большим плюсом.",
    },
]