import React from "react";
import StoryPg from "../pages/pg_Story";
import WishesPg from "../pages/pg_Wishes";
import PortfolioPg from "../pages/pg_Portfolio";
import ReviewsPg from "../pages/pg_Reviews";
import FilesPg from "../pages/pg_Files";
import MainPg from "../pages/pg_Main";
import AnswersPg from "../pages/pg_Answers";
import SkillsPg from "../pages/pg_Services";
import PaymentGood from "../pages/pg_PaymentGood";
import PgWork from "../pages/pg_Work";

export const publicRoutes = [
    {name_en: 'Main', name_ru: 'Главная', id: 'main', path: '/', component: <MainPg/>, exact: true},
    {name_en: 'Range of Skills', name_ru: 'Спектр навыков', id: 'services', path: '/skills', component: <SkillsPg/>, exact: true},
    {name_en: 'Portfolio', name_ru: 'Портфолио', id: 'portfolio', path: '/portfolio', component: <PortfolioPg/>, exact: true},
    {name_en: 'Career story', name_ru: 'История карьеры', id: 'team', path: '/story', component: <StoryPg/>, exact: true},
    {name_en: 'Reviews of work', name_ru: 'Отзывы о работе', id: 'reviews', path: '/reviews', component: <ReviewsPg/>, exact: true},
    {name_en: 'My Wishes', name_ru: 'Мои Пожелания', id: 'wishes', path: '/wishes', component: <WishesPg/>, exact: true},
    {name_en: 'Documents', name_ru: 'Документы', id: 'files', path: '/files', component: <FilesPg/>, exact: true},
    {name_en: 'Answers', name_ru: 'Ответы', id: 'answers', path: '/answers', component: <AnswersPg/>, exact: true},

    {name_en: 'One work', name_ru: 'Одна работа', id: 'work', path: '/case/:id', component: <PgWork/>, exact: true},
    {name_en: 'Successful payment', name_ru: 'Успешная оплата', id: 'payment-good', path: '/payment-good', component: <PaymentGood/>, exact: true},
    {name_en: 'Error 404', name_ru: 'Ошибка 404', id: '404', path: '*', component: <MainPg/>, exact: true},
]
